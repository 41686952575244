import React from "react";

// Component imports
import Layout from "../components/common/layout";
import Contact from "../components/common/Contact";

// Image imports

// CSS imports

export default ({ data }) => {
  return (
    <Layout fixedFooter={true}>
      <div className="app-navbar-padding">
        <Contact />
      </div>
    </Layout>
  );
};